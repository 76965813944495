import React from 'react';
import '../styles/Terms.css';
import logo from '../assets/logo.png';

const CopyrightPolicy = () => {
  return (
    <div className="terms-page">
      <header className="header">
        <a href="/">
          <img src={logo} alt="Snows ProAm Logo" className="logo" />
        </a>
      </header>
      <h1 style={{ marginTop: '150px', fontSize: '2em', color: '#333', textAlign: 'center', width: '100%' }}>
Copyright Policy</h1>

      <div className="content">
        <section>
          <h3>1. Introduction</h3>
          <p>
            This Copyright Policy outlines the procedures and guidelines for addressing copyright-related matters on the Snows ProAm mobile application (the “App”). Snows ProAm Inc. (“we,” “us,” or “our”) respects the intellectual property rights of others and expects our users to do the same.
          </p>
        </section>

        <section>
          <h3>2. Copyright Ownership</h3>
          <p>
            All content and materials available on the App, including but not limited to text, images, graphics, audio, videos, software, and any other intellectual property, are protected by copyright laws and are the property of Snows ProAm Inc. or its content suppliers.
          </p>
        </section>

        <section>
          <h3>3. Reporting Copyright Infringements</h3>
          <p>
            We take copyright infringement seriously and strive to respect the intellectual property rights of others. If you believe that your copyrighted work has been used or displayed on the App in a way that constitutes copyright infringement, please provide us with the following information:
          </p>
          <ul>
            <li>A description of the copyrighted work you believe has been infringed.</li>
            <li>A description of the content you believe to be infringing and its location on the App.</li>
            <li>Your contact information, including your name, address, telephone number, and email address.</li>
            <li>A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
            <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
          </ul>
          <p>
            Please send this information to us at <a href="mailto:info@snowsproam.com">info@snowsproam.com</a>, so that we may forward it to our Designated Copyright Agent.
          </p>
        </section>

        <section>
          <h3>4. Action on Copyright Infringement Reports</h3>
          <p>
            Upon receiving a proper notice of copyright infringement, we will take appropriate actions, which may include:
          </p>
          <ul>
            <li>Removing or disabling access to the allegedly infringing content.</li>
            <li>Notifying the user who posted the content about the complaint.</li>
            <li>Termination of user accounts or access for repeat infringers.</li>
          </ul>
        </section>

        <section>
          <h3>5. Counter-Notification</h3>
          <p>
            If a user believes their content was removed or disabled as a result of an incorrect copyright infringement report, they may send a counter-notification to our Designated Copyright Agent. The counter-notification should include:
          </p>
          <ul>
            <li>Identification of the content that was removed or disabled and its location.</li>
            <li>User’s contact information, including their name, address, telephone number, and email address.</li>
            <li>A statement under penalty of perjury that the user has a good-faith belief that the content was removed or disabled as a result of a mistake or misidentification.</li>
            <li>A statement that the user consents to the jurisdiction of the Irish courts and that they will accept service of process from the person who provided the original copyright infringement report.</li>
          </ul>
          <p>
            Please send the counter-notification to our Designated Copyright Agent at <a href="mailto:info@snowsproam.com">info@snowsproam.com</a>.
          </p>
        </section>

        <section>
          <h3>6. Changes to this Copyright Policy</h3>
          <p>
            We may update this Copyright Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify users of any material changes by posting the updated Copyright Policy on our website or within the App.
          </p>
        </section>
      </div>
    </div>
  );
};

export default CopyrightPolicy;
