import React from 'react';
import '../styles/Terms.css';
import logo from '../assets/logo.png';

const Terms = () => {
  return (
    <div className="terms-page">
      <header className="header">
  <a href="/">
    <img src={logo} alt="Snows ProAm Logo" className="logo" />
  </a>
</header>

<h1 style={{ marginTop: '150px', fontSize: '2em', color: '#333', textAlign: 'center', width: '100%' }}>
  Terms & Service
</h1>


      <div className="content">
        <section>
          <h3>1. Acceptance of Terms</h3>
          <p>
            By downloading, installing, or using the Snows ProAm application, you agree to be bound by these Terms of Use. If you do not agree to these Terms, do not use the App.
          </p>
        </section>

        <section>
          <h3>2. Description of Service</h3>
          <p>
            Snows ProAm provides a platform for users to book sports locations and match with others using geolocation services.
          </p>
        </section>

        <section>
          <h3>3. Eligibility</h3>
          <p>
            You must be at least 12 years old to use the App. By using the App, you represent and warrant that you meet this requirement.
          </p>
        </section>

        <section>
          <h3>4. Account Registration</h3>
          <p>
            To use the Service, you must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account and password.
          </p>
        </section>

        <section>
          <h3>5. Use of the App</h3>
          <p>
            You agree to use the App only for lawful purposes. You will not use the App to harm, threaten, or harass any person or organization. You will not use the App to distribute spam, viruses, or any other harmful software.
          </p>
        </section>

        <section>
          <h3>6. Geolocation Services</h3>
          <p>
            The App uses geolocation services to provide location-based features. By using the App, you consent to the collection and use of your geolocation information as described in our Privacy Policy.
          </p>
        </section>

        <section>
          <h3>7. Booking and Payments</h3>
          <p>
            All bookings made through the App are subject to availability. You agree to pay all fees associated with your bookings. All payments are non-refundable unless otherwise stated.
          </p>
        </section>

        <section>
          <h3>8. User Conduct</h3>
          <p>
            You agree not to:
            <br />- Post or transmit any content that is defamatory, obscene, or otherwise objectionable.
            <br />- Engage in any activity that interferes with or disrupts the Service.
          </p>
        </section>

        <section>
          <h3>9. Termination</h3>
          <p>
            We reserve the right to terminate or suspend your account at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users.
          </p>
        </section>

        <section>
          <h3>10. Limitation of Liability</h3>
          <p>
            Snows ProAm will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
          </p>
        </section>

        <section>
          <h3>11. Changes to Terms</h3>
          <p>
            We may modify these Terms at any time. We will notify you of any changes by posting the new Terms on the App. Your continued use of the App following the posting of changes constitutes your acceptance of the new Terms.
          </p>
        </section>

        <section>
          <h3>12. Governing Law</h3>
          <p>
            These Terms are governed by the laws of Ireland, without regard to its conflict of laws principles.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Terms;
