import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LaunchPage from './components/LaunchPage'; // Adjust the import path as needed
import Profile from './components/Profile'; // Adjust the import path as needed
import TermsOfService from './components/Terms'; // Adjust the import path as needed
import PrivacyPolicy from './components/PrivacyPolicy'; // Adjust the import path as needed
import CopyrightPolicy from './components/CopyrightPolicy';
import Support from './components/Support';
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LaunchPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/copyrightpolicy" element={<CopyrightPolicy />} />
        <Route path="/support" element={<Support />} />



      </Routes>
    </Router>
  );
};

export default App;
