import React, { useEffect, useRef, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import {  setDoc, getDoc, doc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import '../styles/LaunchPage.css';

import appstore from '../assets/appstore.png';
import playstore from '../assets/playstore.png';
import logo from '../assets/logo.png';
import video1 from '../assets/basketball1.mp4';
import video2 from '../assets/basketball2.mp4';
import video3 from '../assets/football3.mp4';
import video4 from '../assets/football1.mp4';
import video5 from '../assets/football2.mp4';
import video6 from '../assets/basketball7.mp4';
import video7 from '../assets/tennis.mp4';
import video8 from '../assets/football4.mp4';

import SplineCanvas from './SplineModel';

const LaunchPage = () => {
  const auth = getAuth();
  const videoRef = useRef(null);
  const currentVideoIndex = useRef(0);
  const sectionsRef = useRef([]);
  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    country: '',
    sport: '',
    invitedAthletes: '', // New field for invited athletes
    timestamp: '', // New field for timestamp
  });

  const [signInData, setSignInData] = useState({
    email: '',
    password: ''
  });

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const videoFiles = useMemo(() => [video1, video2, video8, video3, video6, video7, video4, video5], []);

  const burgerMenuRef = useRef(null);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navigateToProfile = () => {
    setIsDropdownOpen(false);
    navigate('/profile');
  };

 


 

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      burgerMenuRef.current &&
      !burgerMenuRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };


  const [isFading, setIsFading] = useState(false); // Add a state for controlling fade

  useEffect(() => {
    const videoElement = videoRef.current;
  
    const handleVideoEnd = () => {
      setIsFading(true); // Start fade-out effect
      setTimeout(() => {
        currentVideoIndex.current = (currentVideoIndex.current + 1) % videoFiles.length;
        videoElement.src = videoFiles[currentVideoIndex.current];
        videoElement.currentTime = 0;
        videoElement.play().catch((error) => {
          console.error('Error playing video:', error);
        });
        setIsFading(false); // Start fade-in effect
      }, 500); // Adjust the delay to match the fade duration
    };
  
    if (videoElement) {
      videoElement.src = videoFiles[currentVideoIndex.current];
      videoElement.play().catch((error) => {
        console.error('Error playing video:', error);
      });
      videoElement.addEventListener('ended', handleVideoEnd);
    }
  
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, [videoFiles]);
  
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  useEffect(() => {
    const handleScroll = () => {
      sectionsRef.current.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          section.classList.add('show');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger once on mount to show elements already in view

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        fetchUserProfile(user.uid);
      } else {
        setUser(null);
        setProfile(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSignInChange = (e) => {
    const { name, value } = e.target;
    setSignInData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formStep === 1) {
        setFormStep(2);
    } else {
        try {
            const timestamp = new Date().toISOString(); // Create a timestamp
            const emailRef = doc(firestore, 'betaWaitingList', formData.email);
            const emailDoc = await getDoc(emailRef);

            if (emailDoc.exists()) {
                // Email exists, append the new invited athletes to the existing list
                const existingData = emailDoc.data();
                const updatedAthletes = existingData.invitedAthletes 
                    ? `${existingData.invitedAthletes}, ${formData.invitedAthletes}` 
                    : formData.invitedAthletes;
                
                await setDoc(emailRef, {
                    ...existingData,
                    invitedAthletes: updatedAthletes,
                    timestamp, // Update the timestamp
                });
            } else {
                // Email does not exist, create a new document
                await setDoc(emailRef, {
                    ...formData,
                    timestamp, // Add timestamp to the document
                });
            }

            alert('You have successfully joined the beta waiting list!');
            setFormStep(1);
            setFormData({ email: '', fullName: '', country: '', sport: '', invitedAthletes: '', timestamp: '' });
        } catch (error) {
            console.error('Error processing the form: ', error);
        }
    }
};


  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, signInData.email, signInData.password);
      closeModal();
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const fetchUserProfile = async (uid) => {
    const docRef = doc(firestore, 'profiles', uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setProfile(docSnap.data());
    } else {
      console.error('No such document!');
    }
  };

  const handleSignOut = async () => {
    await auth.signOut();
    setIsDropdownOpen(false);
  };

  return (
    <div className="launch-page">
      <header className="header">
        <img src={logo} alt="Snows ProAm Logo" className="logo" />
        <div className="header-right">
          {!user ? (
            <div className="sign-in-text" onClick={openModal}>
              Sign In
            </div>
          ) : (
            <div className="user-controls">
<div className="welcome-message">
  Solomon Snow
</div>


              <div className="burger-menu" onClick={toggleDropdown}>
                <FontAwesomeIcon icon={faBars} />
              </div>
              {isDropdownOpen && (
        <div
          className="dropdown-menu"
          ref={dropdownRef}
        >
           <div className="dropdown-item" onClick={navigateToProfile}>Profile</div>
                  <div className="dropdown-item" onClick={handleSignOut}>Log Out</div>
        </div>
      )}
            </div>
          )}
        </div>
      </header>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <img src={logo} alt="Snows ProAm Logo" className="modal-logo" />
            <form onSubmit={handleSignIn}>
              <input
                type="email"
                name="email"
                value={signInData.email}
                onChange={handleSignInChange}
                placeholder="Email"
                required
              />
              <div className="password-container">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={signInData.password}
                  onChange={handleSignInChange}
                  placeholder="Password"
                  required
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              </div>
              <button type="submit">Sign In</button>
            </form>
          </div>
        </div>
      )}

      <div className="video-container">
      <video 
  ref={videoRef} 
  muted 
  playsInline 
  className={isFading ? 'fade-out' : 'fade-in'}
></video>
      </div>

      <div className="content-container">
        <div className="beta-signup">
          <h2>Join the Beta Waiting List</h2>
          

          <form onSubmit={handleSubmit}>
            {formStep === 1 && (
              <>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                  required
                />
                <button type="submit">Join Now</button>
              </>
            )}
            {formStep === 2 && (
              <>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  placeholder="Enter your full name"
                  required
                />
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  placeholder="Enter your country"
                  required
                />
                <select
                  name="sport"
                  value={formData.sport}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select your sport</option>
                  <option value="basketball">Basketball</option>
                  <option value="football">Football</option>
                  <option value="tennis">Tennis</option>
                  <option value="volleyball">Volleyball</option>
                  <option value="padel">Padel</option>
                  <option value="futsal">Futsal</option>
                  <option value="hockey">Hockey</option>
                  <option value="baseball">Baseball</option>
                  <option value="rugby">Rugby</option>
                  <option value="cricket">Cricket</option>
                  <option value="swimming">Swimming</option>
                  <option value="golf">Golf</option>
                  <option value="athletics">Athletics</option>
                  {/* Add more sports as needed */}
                </select>
                <h4>
  For a chance to win up to 1000 euro/usd, tell us the names of the athletes you tagged & invited.<br />
  If you invite more, just come back, use the same details, and the new names will be added to your previous list. The winners will recieve an email from Snows ProAm in regards to the prize money by the end of the month.
</h4>

                <input
                  type="text"
                  name="invitedAthletes"
                  value={formData.invitedAthletes}
                  onChange={handleInputChange}
                  placeholder="Sean Smith, Trey Brune, Alex Nuer, "
                  required
                />
                <button type="submit">Submit</button>
              </>
            )}
          </form>
        </div>
        <h2>Interact with me  </h2>
        <h4 style={{ textAlign: 'center' }}>
  For Phone Users: 2 fingers to orbit, 3 fingers to pan.
</h4>


        <div className="spline-container">
          <SplineCanvas />
        </div>
      </div>

      <footer className="footer">
        <h2>Be the First in the Game Join our Beta Team!</h2>
        <div className="buttons">
  <a href="https://apps.apple.com/ie/app/snows-proam/id6504996530" target="_blank" rel="noopener noreferrer">
    <img src={appstore} alt="Download on iOS" className="store-button" />
  </a>
  <a href="https://play.google.com/store/games?hl=en" target="_blank" rel="noopener noreferrer">
    <img src={playstore} alt="Download on Android" className="store-button" />
  </a>
</div>

        <div className="links">
          <a href="/terms">Terms of Service</a>
          <a href="/privacypolicy">Privacy Policy</a>
          <a href="/copyrightpolicy">Copyright Policy</a>
          <a href="/support">Support</a>

        </div>
        <div className="social-icons">
          <a href="https://facebook.com/snowsproam" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://twitter.com/snowsproam" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://instagram.com/snowsproam" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://tiktok.com/snowsproam" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default LaunchPage;
