import React from 'react';
import '../styles/PrivacyPolicy.css';
import logo from '../assets/logo.png';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <header className="header">
        <a href="/">
          <img src={logo} alt="Snows ProAm Logo" className="logo" />
        </a>
      </header>

      <h1 style={{ marginTop: '150px', fontSize: '2em', color: '#333', textAlign: 'center', width: '100%' }}>
        Privacy Policy
      </h1>
   
      <div className="content">
        <section>
          <h3>1. Introduction</h3>
          <p>
            This Privacy Policy describes how Snows ProAm collects, uses, and discloses your information when you use our App.
          </p>
        </section>

        <section>
          <h3>2. Information We Collect</h3>
          <p>
            <strong>Personal Information:</strong> Name, email address and other information you provide during registration.
            <br /><strong>Geolocation Data:</strong> Precise location information collected through your device's GPS.
            <br /><strong>Usage Data:</strong> Information about your use of the App, including the features you use and the time spent on the App.
          </p>
        </section>

        <section>
          <h3>3. How We Use Your Information</h3>
          <p>
            We use your information to:
            <br />- Provide and improve our Service.
            <br />- Facilitate bookings and payments.
            <br />- Match you with other users for sports activities.
            <br />- Communicate with you about your account and bookings.
            <br />- Ensure the security of our App and users.
          </p>
        </section>

        <section>
          <h3>4. Sharing Your Information</h3>
          <p>
            We may share your information with:
            <br /><strong>Service Providers:</strong> Third-party companies that provide services on our behalf, such as payment processing and geolocation services.
            <br /><strong>Law Enforcement:</strong> If required by law or if we believe that such action is necessary to comply with legal obligations or protect our rights.
          </p>
        </section>

        <section>
          <h3>5. Geolocation Data</h3>
          <p>
            We use geolocation data to provide location-based services, such as finding nearby sports facilities and matching you with other users. Geolocation data is collected through your device's GPS when you give us permission.
          </p>
          <p>
            <strong>How We Request and Use Geolocation Data:</strong>
            <br />- <strong>Permission:</strong> We will request your permission to access your location the first time you use any location-based feature. You will receive a pop-up notification asking for permission to access your location.
            <br />- <strong>Ongoing Consent:</strong> If you grant access, we collect your location data in real time while the app is open and in use. You will need to grant permission each time you start the app or re-enable location services.
            <br />- <strong>Background Location:</strong> We do not track your location when the app is closed. Location tracking only occurs while the app is actively running.
            <br />- <strong>Usage:</strong> We use your location data to:
              <ul>
                <li>Show you nearby sports facilities and events.</li>
                <li>Help match you with other users for sports activities based on proximity.</li>
                <li>Improve the accuracy of venue and event suggestions based on your real-time location.</li>
              </ul>
          </p>
          <p>
            <strong>Managing Geolocation Permissions:</strong>
            <br />- You can disable geolocation services at any time through your device's settings. However, doing so will limit your ability to use location-dependent features such as finding nearby facilities or matching with users.
          </p>
        </section>
        <section>
  <h3>6. Use of Step Data for Fitness and Track Rankings</h3>
  <p>
    If you create an account for Fitness or Track activities, your step data will be collected and used to display your ranking in regional, national, and global leaderboards. This data will be publicly visible to other users to highlight your achievements and progress in these sports.
  </p>
  <p>
    <strong>How Step Data is Used:</strong>
    <ul>
      <li>Your steps are tracked and used to calculate your rank on leaderboards based on activity levels.</li>
      <li>Your ranking may be displayed at the regional, national, and global levels depending on your performance.</li>
      <li>Other users may view your ranking and step data in the leaderboard for Fitness or Track.</li>
    </ul>
  </p>
  <p>
    If you do not wish to have your step data collected or displayed for Fitness or Track, you must delete your account for these sports. You may continue to use the app for other sports without participating in step-based rankings.
  </p>
  <p>
    To delete your Fitness or Track account, please visit the account settings page and follow the steps to remove your account from those specific activities.
  </p>
  <p>
    <strong>By continuing to create an account, you are agreeing to the collection and display of your step data as described above.</strong>
  </p>
</section>


        <section>
          <h3>7. Data Security</h3>
          <p>
          <p>
  We take the security of your information very seriously and employ industry-standard measures to protect your data. While we strive to use the most effective safeguards available, it's important to understand that no method of data transmission over the internet or method of electronic storage can be guaranteed to be completely secure. However, we are continuously improving our security protocols to protect your personal information to the highest standards possible.
</p>
          </p>
        </section>

        <section>
          <h3>8. Data Retention</h3>
          <p>
            We retain your information for as long as your account is active or as needed to provide you with the Service. We may also retain and use your information to comply with our legal obligations, resolve disputes, and enforce our agreements.
          </p>
        </section>

        <section>
          <h3>9. Your Rights</h3>
          <p>
            You have the right to:
            <br />- Access, correct, or delete your personal information.
            <br />- Object to or restrict the processing of your personal information.
            <br />- Withdraw your consent at any time.
            <br />To exercise these rights, please contact us at info@snowsproam.com.
          </p>
        </section>

        <section>
          <h3>10. Changes to This Privacy Policy</h3>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the App. Your continued use of the App after the changes have been posted constitutes your acceptance of the new Privacy Policy.
          </p>
        </section>

        <section>
          <h3>11. Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy, please contact us at info@snowsproam.com.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
