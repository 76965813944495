import React, { useEffect, useState, useRef } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { getDoc, doc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { firestore, storage } from '../firebase'; // Ensure you have your firebase configuration here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import '../styles/Profile.css';
import logo from '../assets/logo.png'; // Ensure you have your logo path here
import { Link } from 'react-router-dom';

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [editableField, setEditableField] = useState(null);
  const [fieldValue, setFieldValue] = useState('');
  const [expandedSport, setExpandedSport] = useState(null);
  const [sportDetails, setSportDetails] = useState({});
  const [expandedTeam, setExpandedTeam] = useState(null);
  const [teamDetails, setTeamDetails] = useState({});
  const [expandedBooking, setExpandedBooking] = useState(null);
  const [bookingDetails, setBookingDetails] = useState({});
  const [bookings, setBookings] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const auth = getAuth();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(firestore, 'profiles', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setProfileData(docSnap.data());
          // Fetch bookings
          const bookingsCollectionRef = collection(firestore, `profiles/${user.uid}/bookings`);
          const bookingsSnapshot = await getDocs(bookingsCollectionRef);
          const bookingsData = bookingsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setBookings(bookingsData);
        } else {
          console.error('No such document!');
        }
      }
    };

    fetchProfileData();
  }, [auth]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEdit = (field, value) => {
    setEditableField(field);
    setFieldValue(value);
  };

  const handleChange = (e) => {
    setFieldValue(e.target.value);
  };

  const handleUpdate = async (field) => {
    if (auth.currentUser) {
      const docRef = doc(firestore, 'profiles', auth.currentUser.uid);
      await updateDoc(docRef, {
        [field]: fieldValue,
      });
      setProfileData({ ...profileData, [field]: fieldValue });
      setEditableField(null);
      alert('Profile updated successfully!');
    }
  };

  const toggleSportDetails = async (sport) => {
    if (expandedSport === sport) {
        setExpandedSport(null);
        return;
    }
    setExpandedSport(sport);

    if (!sportDetails[sport]) {
        const userId = auth.currentUser.uid;
        const sportCollectionRef = collection(firestore, `profiles/${userId}/sports`);
        const sportDocRef = doc(sportCollectionRef, sport);
        const sportSnap = await getDoc(sportDocRef);

        if (sportSnap.exists()) {
            const details = sportSnap.data();
            setSportDetails(prevState => ({
                ...prevState,
                [sport]: details,
            }));
        } else {
            console.error('No such document!');
        }
    }
  };

  const toggleTeamDetails = async (team) => {
    if (expandedTeam === team) {
      setExpandedTeam(null);
      return;
    }
    setExpandedTeam(team);

    if (!teamDetails[team]) {
      const teamRef = doc(firestore, 'teams', team);
      const teamSnap = await getDoc(teamRef);

      if (teamSnap.exists()) {
        const details = teamSnap.data();
        setTeamDetails(prevState => ({
          ...prevState,
          [team]: details,
        }));
      } else {
        console.error('No such document!');
      }
    }
  };

  const toggleBookingDetails = async (bookingId) => {
    if (expandedBooking === bookingId) {
      setExpandedBooking(null);
      return;
    }
    setExpandedBooking(bookingId);

    if (!bookingDetails[bookingId]) {
      const bookingRef = doc(firestore, `profiles/${auth.currentUser.uid}/bookings`, bookingId);
      const bookingSnap = await getDoc(bookingRef);

      if (bookingSnap.exists()) {
        const details = bookingSnap.data();
        setBookingDetails(prevState => ({
          ...prevState,
          [bookingId]: details,
        }));
      } else {
        console.error('No such document!');
      }
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSignOut = async () => {
    await signOut(auth);
    navigate('/');
  };

  const handleUpdateProfileImg = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`profileImgs/${auth.currentUser.uid}`);
      await fileRef.put(file);
      const fileUrl = await fileRef.getDownloadURL();

      if (auth.currentUser) {
        const docRef = doc(firestore, 'profiles', auth.currentUser.uid);
        await updateDoc(docRef, {
          profileImg: fileUrl,
        });
        setProfileData({ ...profileData, profileImg: fileUrl });
        alert('Profile image updated successfully!');
      }
    }
  };

  if (!profileData) {
    return (
      <div className="loading-container">
        <Circles
          height="100"
          width="100"
          color="#ffffff"
          ariaLabel="loading"
        />
      </div>
    );
  }
  
  

  return (
    <div className="profile-page">
      <header className="header">
        <div className="header-left"></div>
        <Link to="/"><img src={logo} alt="Snows ProAm Logo" className="logo" /></Link>
        <div className="header-right">
          <div className="user-controls">
            <div className="welcome-message">
              {profileData.name} {profileData.surname}
            </div>
            <div className="burger-menu" onClick={toggleDropdown}>
              <FontAwesomeIcon icon={faBars} />
            </div>
            {isDropdownOpen && (
              <div className="dropdown-menu" ref={dropdownRef}>
                <div className="dropdown-item" onClick={() => navigate('/')}>Home</div>
                <div className="dropdown-item" onClick={handleSignOut}>Log Out</div>
              </div>
            )}
          </div>
        </div>
      </header>

      <div className="profile-header">
        <img src={profileData.profileImg} alt="Profile" className="profile-img" />
        <div className="file-input-container">
  <label htmlFor="fileInput" className="custom-file-upload">
    Choose An Image
  </label>
  <input
    type="file"
    id="fileInput"
    style={{ display: "none" }} /* Hides the default input */
    onChange={handleUpdateProfileImg}
  />
  <p id="fileName" className="upload-instruction">No file chosen</p> 
</div>

        <h1>{profileData.name} {profileData.surname}</h1>
        <h2>@{profileData.username}</h2>
      </div>

      <div className="profile-section">
        <h3>Personal Information</h3>
        {editableField === 'age' ? (
          <div ref={inputRef}>
            <input type="text" value={fieldValue} onChange={handleChange} />
            <button className="update-btn" onClick={() => handleUpdate('age')}>Update</button>
          </div>
        ) : (
          <p><strong>Age:</strong> {profileData.age} <button className="edit-btn" onClick={() => handleEdit('age', profileData.age)}>Edit</button></p>
        )}
        {editableField === 'gender' ? (
          <div ref={inputRef}>
            <input type="text" value={fieldValue} onChange={handleChange} />
            <button className="update-btn" onClick={() => handleUpdate('gender')}>Update</button>
          </div>
        ) : (
          <p><strong>Gender:</strong> {profileData.gender} <button className="edit-btn" onClick={() => handleEdit('gender', profileData.gender)}>Edit</button></p>
        )}
        {editableField === 'country' ? (
          <div ref={inputRef}>
            <input type="text" value={fieldValue} onChange={handleChange} />
            <button className="update-btn" onClick={() => handleUpdate('country')}>Update</button>
          </div>
        ) : (
          <p><strong>Country:</strong> {profileData.country} <button className="edit-btn" onClick={() => handleEdit('country', profileData.country)}>Edit</button></p>
        )}
        {editableField === 'region' ? (
          <div ref={inputRef}>
            <input type="text" value={fieldValue} onChange={handleChange} />
            <button className="update-btn" onClick={() => handleUpdate('region')}>Update</button>
          </div>
        ) : (
          <p><strong>Region:</strong> {profileData.region} <button className="edit-btn" onClick={() => handleEdit('region', profileData.region)}>Edit</button></p>
        )}
        {editableField === 'address' ? (
          <div ref={inputRef}>
            <input type="text" value={fieldValue} onChange={handleChange} />
            <button className="update-btn" onClick={() => handleUpdate('address')}>Update</button>
          </div>
        ) : (
          <p><strong>Address:</strong> {profileData.address} <button className="edit-btn" onClick={() => handleEdit('address', profileData.address)}>Edit</button></p>
        )}
      </div>
      <div className="profile-section">
        <h3>Contact Information</h3>
        {editableField === 'email' ? (
          <div ref={inputRef}>
            <input type="text" value={fieldValue} onChange={handleChange} />
            <button className="update-btn" onClick={() => handleUpdate('email')}>Update</button>
          </div>
        ) : (
          <p><strong>Email:</strong> {profileData.email} <button className="edit-btn" onClick={() => handleEdit('email', profileData.email)}>Edit</button></p>
        )}
        {editableField === 'phoneNumber' ? (
          <div ref={inputRef}>
            <input type="text" value={fieldValue} onChange={handleChange} />
            <button className="update-btn" onClick={() => handleUpdate('phoneNumber')}>Update</button>
          </div>
        ) : (
          <p><strong>Phone Number:</strong> {profileData.phoneNumber} <button className="edit-btn" onClick={() => handleEdit('phoneNumber', profileData.phoneNumber)}>Edit</button></p>
        )}
      </div>
      <div className="profile-section">
        <h3>Physical Attributes</h3>
        {editableField === 'height' ? (
          <div ref={inputRef}>
            <input type="text" value={fieldValue} onChange={handleChange} />
            <button className="update-btn" onClick={() => handleUpdate('height')}>Update</button>
          </div>
        ) : (
          <p><strong>Height:</strong> {profileData.height} cm <button className="edit-btn" onClick={() => handleEdit('height', profileData.height)}>Edit</button></p>
        )}
        {editableField === 'weight' ? (
          <div ref={inputRef}>
            <input type="text" value={fieldValue} onChange={handleChange} />
            <button className="update-btn" onClick={() => handleUpdate('weight')}>Update</button>
          </div>
        ) : (
          <p><strong>Weight:</strong> {profileData.weight} kg <button className="edit-btn" onClick={() => handleEdit('weight', profileData.weight)}>Edit</button></p>
        )}
      </div>
      <div className="profile-section">
        <h3>Sports</h3>
        <ul>
          {profileData.sports.map((sport, index) => (
            <li key={index} onClick={() => toggleSportDetails(sport)}>
              <span>{sport}</span>
              {expandedSport === sport && sportDetails[sport] && (
                <div className="sport-details">
                  {Object.keys(sportDetails[sport]).map((key, i) => (
                    <p key={i}><strong>{key.replace(/_/g, ' ')}:</strong> {sportDetails[sport][key]}</p>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="profile-section">
        <h3>Teams</h3>
        <ul>
          {profileData.teams.map((team, index) => (
            <li key={index} onClick={() => toggleTeamDetails(Object.keys(team)[0])}>
              <span>{Object.keys(team)[0]}</span>
              {expandedTeam === Object.keys(team)[0] && teamDetails[Object.keys(team)[0]] && (
                <div className="team-details">
                  {Object.keys(teamDetails[Object.keys(team)[0]]).map((key, i) => (
                    <p key={i}><strong>{key.replace(/_/g, ' ')}:</strong> {teamDetails[Object.keys(team)[0]][key]}</p>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
     
      <div className="profile-section">
        <h3>ProAm Information</h3>
        <p><strong>Level:</strong> {profileData.level}</p>
        <p><strong>ProAm Coins:</strong> {profileData.proamCoins}</p>
        <p><strong>XP:</strong> {profileData.xp}</p>
      </div>
      <div className="profile-section">
        <h3>Bookings</h3>
        <ul>
          {bookings.map((booking, index) => (
            <li key={index} onClick={() => toggleBookingDetails(booking.id)}>
              <span>{booking.locationName}</span>
              {expandedBooking === booking.id && bookingDetails[booking.id] && (
                <div className="booking-details">
                  {Object.keys(bookingDetails[booking.id]).map((key, i) => (
                    <p key={i}><strong>{key.replace(/_/g, ' ')}:</strong> {bookingDetails[booking.id][key]}</p>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Profile;
