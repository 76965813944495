import React, { useState } from 'react';
import '../styles/Support.css'; // Custom CSS styles
import logo from '../assets/logo.png';
import {  setDoc, doc } from 'firebase/firestore';
import { firestore } from '../firebase'; // Ensure you have your firebase configuration here

const Support = () => {
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // Declare errorMessage state


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (feedback.trim()) {
      try {
        console.log('Submitting feedback...'); // Debug: Submission started

        const timestamp = new Date().toISOString();
        const feedbackId = `feedback_${Date.now()}`; // Create a unique identifier
        console.log('Generated feedback ID: ', feedbackId); // Debug: Log feedback ID

        const feedbackRef = doc(firestore, 'feedback', feedbackId);
        
        // Debug: Log reference to the feedback document
        console.log('Feedback document reference: ', feedbackRef);

        await setDoc(feedbackRef, {
          feedback,
          timestamp,
        });

        console.log('Feedback successfully submitted!'); // Debug: Success

        setSubmitted(true);
        setFeedback('');
      } catch (error) {
        console.error('Error submitting feedback: ', error); // Debug: Error details
        setErrorMessage('There was an issue submitting your feedback. Please try again later.');
      }
    } else {
      console.log('Feedback is empty. Please enter feedback.'); // Debug: Feedback validation
    }
  };


  return (
    <div className="support-page">
      <header className="header">
        <a href="/">
          <img src={logo} alt="Snows ProAm Logo" className="logo" />
        </a>
      </header>

      <h1 className="page-title">Support</h1>

      <div className="content">
        <section>
          <h2>How Can We Help You?</h2>
          <p>
            At Snows ProAm, we are dedicated to providing you with the best possible experience. Whether you’re scheduling a game, joining a community, or competing in matches, we’re here to assist with any questions or issues you may have.
          </p>


        </section>

        <section className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <ul>
            <li>
              <strong>1. How do I book a sports location?</strong><br />
              You can book a location by going to the Home screen, selecting "Book a Location," and choosing a venue and time slot. Pay via ProAm Coins or card.
            </li>
            <li>
              <strong>2. How do I join or create a community?</strong><br />
              Navigate to the Chat screen, select the Community Section, and either browse existing communities or create a new one with the "Add" button.
            </li>
            <li>
              <strong>3. How do I invite friends to join my game or team?</strong><br />
              Go to the Sports section in the Home screen, tap on your team, and select "Invite" to send an invitation to your friends.
            </li>
            <li>
              <strong>4. How can I manage my team and team members?</strong><br />
              From the Sports section, select your team and access the team profile. Here, you can add or remove members and modify team settings.
            </li>
            <li>
              <strong>5. How do I stake ProAm Coins in a match?</strong><br />
              Ensure your account is verified in the profile settings. Once verified, use the "Stake Coins" feature before a match to set the stakes.
            </li>
            <li>
              <strong>6. How does the leaderboard ranking work?</strong><br />
              The leaderboard is based on your trophies, which you earn by winning matches. You can filter rankings globally, nationally, or regionally.
            </li>
            <li>
              <strong>7. What happens if I lose connection during a match?</strong><br />
              If you lose connection mid-match, the system will attempt to reconnect you. If not, the match will be considered forfeited and you may lose trophies.
            </li>
            <li>
              <strong>8. How can I reset my password?</strong><br />
              Go to the Profile screen, tap on "Settings," and select "Change Password" to reset your password securely.
            </li>
            <li>
              <strong>9. How do I suggest a new sports venue?</strong><br />
              On the Map screen, use the "Flag" button to suggest a new venue. Enter the location details and upload an image if available.
            </li>
            <li>
              <strong>10. Can I play sports in different locations around the world?</strong><br />
              Yes, the app allows you to explore sports locations globally. You can adjust the search radius or filter locations to find places near your travel destinations.
            </li>
            <li>
              <strong>11. What are ProAm Coins used for?</strong><br />
              ProAm Coins can be used for booking venues, staking in matches, enrolling in tournaments, or other in-app purchases.
            </li>
            <li>
              <strong>12. How do I create a private training session?</strong><br />
              Use the "Add Session" feature in the Schedule section, select "Private," and invite participants directly. The location will be hidden until they’re accepted.
            </li>
            <li>
              <strong>13. How do I view past bookings?</strong><br />
              Tap the filter icon on the Bookings screen to view all your past bookings. You can access details such as venue name, date, and time.
            </li>
            <li>
              <strong>14. How do I check weather conditions before a game?</strong><br />
              The weather icon on the Matchmaking screen provides real-time weather updates for your current or selected location.
            </li>
            <li>
              <strong>15. What happens when I forfeit a match?</strong><br />
              If you forfeit, you automatically lose, and trophies will be deducted from your total based on the match outcome.
            </li>
            <li>
              <strong>16. How do I upload and share highlight videos?</strong><br />
              Go to the Highlights screen, tap the "Add" icon, upload your video, add a caption, and it will be shared with the community.
            </li>
            <li>
              <strong>17. Can I change the sport I’m currently focused on?</strong><br />
              Yes, you can switch between different sports by going to the Home screen and selecting your preferred sport from the Sports tab.
            </li>
            <li>
              <strong>18. How do I track my steps and trophies?</strong><br />
              The Fitness section automatically tracks your steps and converts them into trophies, contributing to your leaderboard ranking.
            </li>
            <li>
              <strong>19. How do I enrol in tournaments?</strong><br />
              Open the Tournaments section in the Home screen, select the tournament you want to join, and enrol by using ProAm Coins.
            </li>
            <li>
              <strong>20. How can I report an issue with another player?</strong><br />
              Use the "Flag" button during a match or in the player’s profile to report any rule violations or inappropriate behaviour.
            </li>
          </ul>
        </section>

        <section className="feedback-section">
          <h2>Submit Your Feedback</h2>
          {submitted ? (
            <p className="success-message">Thank you for your feedback! We appreciate your input.</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Please describe your issue or provide feedback..."
                className="feedback-textarea"
              />
              <button type="submit" className="submit-button">Submit Feedback</button>
            </form>
          )}
                    {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display the error message */}

        </section>

        <section className="contact-info">
          <h2>Additional Help</h2>
          <p>
            For any further inquiries or issues, feel free to email us at: <a href="mailto:support@snowsproam.com">support@snowsproam.com</a>. You can also explore our detailed guides within the app, which cover features like booking, matchmaking, team management, and community interaction.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Support;
