import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyBTwJraUUKDMb1wgRa3yZve4_8AA_vh3sQ',
    authDomain: 'snows-proam.firebaseapp.com',
    projectId: 'snows-proam',
    storageBucket: 'snows-proam.appspot.com',
    messagingSenderId: '570694638964',
    appId: '1:570694638964:web:a44b832d47e5780cec2021',
    measurementId: 'G-4DRT7VEE60'
};

const firebaseApp = initializeApp(firebaseConfig);

const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);

export { firestore, storage, functions };
